<template>
	<div class="order">
		<labelView></labelView>
		<div class="inner">
			<div class="spXx">
				<addresss></addresss>
				<remark :data='params.remark' @remarksChange="remarksChange"></remark>
				<packa v-if="ydData.length>0" :genre="1" :data="ydData" :freight="ydFreight" :couponNum="couponNum"
					:couponData="couponData.ydData.coupon" @moneyChange="moneyChange" @couponOpen="couponOpen" :seleTime='seleTimeData' @seleTimeOpen="seleTimeOpen"></packa>
				<packa v-if="jsData.length>0" :genre="0" :data="jsData" :freight="jsFreight" :couponNum="couponNum"
					:couponData="couponData.jsData.coupon" :seleTime='seleTimeData' @moneyChange="moneyChange" @couponOpen="couponOpen" @seleTimeOpen="seleTimeOpen"></packa>
			</div>
			<div class="jsxx">
				<ddzy @submit="submit" :total_money="total_money" :freightData="freightData"></ddzy>
			</div>
		</div>
		<coupon ref="coupon" @couponNumber="couponNumber" @seleCoupon="seleCoupon" :couponData="couponData"
			:pay_method="params.pay_method"></coupon>
		<seleTime ref="seleTime" :ydData='ydData' :jsData='jsData' @timeChange="timeChange"></seleTime>
	</div>
</template>

<script>
	import {
		cityDelivery
	} from "@/api/storehouse.js"
import {
	setCookies
} from "@/utils/cookies.js"

	import labelView from "./components/labelView.vue"
	import addresss from "./components/address.vue"
	import remark from "./components/remark.vue"
	import packa from "./components/packa.vue"
	import coupon from "./components/coupon.vue"
	import ddzy from "./components/ddzy.vue"
	import seleTime from "@/components/seleTime.vue"
	export default {
		name: "order",
		components: {
			labelView,
			addresss,
			remark,
			packa,
			coupon,
			ddzy,
			seleTime
		},
		data() {
			return {
				couponData: {
					ydData: {
						coupon: {
							id: 0,
							amount: 0,
							amountSj: 0,
						},
						history: {
							id: 0,
						}
					},
					jsData: {
						coupon: {
							id: 0,
							amount: 0,
							amountSj: 0,
						},
						history: {
							id: 0,
						}
					}
				},
				couponNum: 0,
				params: {
					address_id: 0,
					coupon_id: 0,
					remark: '',
					packs: [],
					pay_method: 0,
					total_money: 0,
					language: 0,
					source: 7,
					openid: "",
					freight:0
				},
				ydData: this.$store.state.cart.setItems.reserve,
				jsData: this.$store.state.cart.setItems.forthwith,
				total_money: 0,
				jsMoney: 0,
				ydMoney: 0,
				ydFreight: 0,
				jsFreight: 0,
				freightData:0,
				seleTimeData: [

				],
				loding:false,
			}
		},
		methods: {
			submit(){
				if(this.loding){
						return false
					}
					this.loding = true
					let freightData = 0
					if (!isNaN(this.freightData)) {
						freightData = this.freightData
					}
					this.params.address_id = this.addressData.id
				
					this.params.total_money = this.total_money+freightData
					this.params.freight = freightData
					let packs = []
					if (this.jsData.length > 0) {
						let cart_ids = []
						this.jsData.map(item => {
							cart_ids.push(Number(item.CartId))
						})
						packs.push({
							genre: 0,
							delivery_time: 0,
							cart_ids: cart_ids,
							coupon_id: this.couponData.jsData.history.id
						})
					}
					if (this.ydData.length > 0) {
						let cart_ids = []
						this.ydData.map(item => {
							cart_ids.push(Number(item.CartId))
						})
						packs.push({
							genre: 1,
							delivery_time: 0,
							cart_ids: cart_ids,
							coupon_id: this.couponData.ydData.history.id
						})
					}
					this.params.packs = packs
					//配送时间
					if (this.seleTimeData[0].delivery_time == 0) {
						this.$message.error(this.i18n('209'))
						this.loding = false
						return false
					}
					if (this.seleTimeData[1]) {
						if (this.seleTimeData[1].delivery_time == 0) {
							this.$message.error(this.i18n('209'))
							this.loding = false
							return false
						}
					}
					this.seleTimeData.map((item) => {
						if (this.params.packs[0].genre == item.genre) {
							if(item.delivery_time!=10001){
								this.params.packs[0].delivery_time = new Date(item.delivery_time).getTime() / 1000
							}else{
								this.params.packs[0].delivery_time = item.delivery_time
							}
						}
						if (this.params.packs[1] && this.params.packs[1].genre == item.genre) {
							if(item.delivery_time!=10001){
								this.params.packs[1].delivery_time = new Date(item.delivery_time).getTime() / 1000
							}else{
								this.params.packs[1].delivery_time = item.delivery_time
							}
						}
					})
					// if (this.params.packs.length == 2 && this.params.packs[0].delivery_time != this.params.packs[1]
					// 	.delivery_time && !this.twoTime) {
					// 	//两个包裹的配送时间 不一致
					// 	this.$refs.twoTimeConfirm.open()
					// 	return false
					// }
					//跳转到支付页面
					this.loding = false
					setCookies('orderData',JSON.stringify(this.params))
					//前往支付
					this.$router.push('/pay?v20=1')
			},
			//选择优惠券
			seleCoupon(data, genre) {
				// data 选中的 优惠券数据
				if (genre == 0) {
					this.couponData.jsData = {
						history: data.history,
						coupon: data.coupon
					}
				} else {
					this.couponData.ydData = {
						history: data.history,
						coupon: data.coupon
					}
				}
				this.freight()
			},
			couponNumber(data) {
				console.log("data")
				console.log(data)
				this.couponNum = data
			},
			couponOpen(genre, totalPrice) {
				this.$refs.coupon.open(genre, totalPrice)
			},
			seleTimeOpen(genre) {
				this.$refs.seleTime.open(genre)
			},
			//运费
			freight() {
				// let data = {
				// 	distance: 0,    //距离
				// 	kilometer_freight: 0,   //每公里的运费价格
				// 	free_shipping_kilometers: 0,   //免费公里数
				// 	basic_freight: 0   //基本运费
				// }
				// freightParameter
				let freightData = 0
				if (this.deliveryData) {
					if (this.jsData.length > 0 && this.ydData.length > 0) {
						// 计算两个包裹的运费
						if (this.seleTimeData.length == 2) {
							if (this.seleTimeData[0].delivery_time == this.seleTimeData[1].delivery_time) {
								//计算运费
								freightData = this.freightParameter.money-this.deliveryData.totalFreights
								if(freightData<=0){
									freightData = this.i18n('182')
									this.ydFreight = this.i18n('182')
									this.jsFreight = this.i18n('182')
								}
								this.ydFreight = freightData
								this.jsFreight = 0
							} else {
								this.jsFreight = this.freightParameter.money-this.deliveryData.jsFreights
								this.ydFreight = this.freightParameter.money-this.deliveryData.ydFreights
								if(this.ydFreight<0){
									this.ydFreight = 0
								}
								if(this.jsFreight<0){
									this.jsFreight = 0
								}
								freightData = this.ydFreight + this.jsFreight
							}
						} else {
							freightData = 0
							this.jsFreight = 0
							this.ydFreight = 0
						}
					} else {
						freightData = this.freightParameter.money-this.deliveryData.totalFreights
						if(freightData<0){
							freightData = 0
						}
						if (this.jsData.length > 0) {
							this.jsFreight = freightData
						} else {
							this.ydFreight = freightData
						}
						if(freightData==0){
							freightData = this.i18n('182')
							this.ydFreight = this.i18n('182')
							this.jsFreight = this.i18n('182')
						}
					}
				} else {
					freightData = 0
				}
				this.freightData = freightData
			},
			//更新配送数据
			async cityDelivery() {
				let storehouse_id = this.$store.state.serviceArea.seleLoca.storehouses[0].storehouse_id
				let delivery = this.$store.state.serviceArea.delivery
				if (!delivery) {
					let {
						data
					} = await cityDelivery()
					delivery = data
					this.$store.commit('serviceArea/setDelivery', data)
				}
				delivery.map(item => {
					if (item.storehouse_id == storehouse_id && item.city_id == this.addressData.city_id) {
						this.deliveryData = item
					}
				})
				this.freight()
			},
			moneyChange(genre, totalPrice) {
				if (genre == 0) {
					this.jsMoney = totalPrice
				} else {
					this.ydMoney = totalPrice
				}
				console.log(100)
				console.log(this.total_money)
				this.total_money = this.jsMoney + this.ydMoney
			},
			remarksChange(data) {
				this.params.remark = data
			},
			//配送时间选择
			timeChange(data) {
				this.seleTimeData = data
				this.freight()
			},
		},
		computed: {
			addressData() {
				return this.$store.state.serviceArea.addressData
			},
			isNoHd() {
				let isNoHd = false
				if (this.couponData.ydData.coupon.id != 0 && this.couponData.ydData.coupon.pay_method != 1) {
					isNoHd = true
				}
				if (this.couponData.jsData.coupon.id != 0 && this.couponData.jsData.coupon.pay_method != 1) {
					isNoHd = true
				}
				return isNoHd
			},
			deliveryData() {
				let rows = this.$store.state.serviceArea.addressDataRows
				let storehouse_id = this.$store.state.serviceArea.seleLoca.storehouses[0].storehouse_id
				// let data = {
				// 	money: 0,
				// 	freight: 0
				// }
				let data = {
					totalFreights:0,
					ydFreights:0,
					jsFreights:0,
				}
				let jsJe = 0
				let ydJe = 0
				if(this.jsData.length>0){
					this.jsData.map(item => {
						jsJe = jsJe + (item.skuAc.price * item.Count)
					})
				}
				if(this.ydData.length>0){
					this.ydData.map(item => {
						ydJe = ydJe + (item.skuAc.price * item.Count)
					})
				}
				rows.map(item=>{
					if(item.distance.storehouse_id==storehouse_id){
						let freights = item.storehouse.freights
						
						freights.sort((a, b) => parseInt(a.costs_over) - parseInt(b.costs_over));
						freights.map(items=>{
							if(this.setItems.total_money>=items.costs_over){
								data.totalFreights=items.off
							}
							if(jsJe>=items.costs_over){
								data.jsFreights=items.off
							}
							if(ydJe>=items.costs_over){
								data.ydFreights=items.off
							}
						})
					}
				})
				return data
			},
			freightParameter(){
				let rows = this.$store.state.serviceArea.addressDataRows
				let storehouse_id = this.$store.state.serviceArea.seleLoca.storehouses[0].storehouse_id
				let data = {
					distance: 0,    //距离
					kilometer_freight: 0,   //每公里的运费价格
					free_shipping_kilometers: 0,   //免费公里数
					basic_freight: 0,   //基本运费
					money:0                //计算结果
				}
				rows.map(item=>{
					if(item.distance.storehouse_id==storehouse_id){
						data.distance=item.distance.distance/1000
						data.kilometer_freight=item.storehouse.storehouse.kilometer_freight
						data.free_shipping_kilometers=item.storehouse.storehouse.free_shipping_kilometers
						data.basic_freight=item.storehouse.storehouse.basic_freight
					}
				})
				//计算运费
				if(data.distance<=data.free_shipping_kilometers){
					data.money=0
				}else{
					data.distance = Math.ceil(data.distance)
					data.money = data.basic_freight+(data.distance-data.free_shipping_kilometers)*data.kilometer_freight
				}
				return data
			}
		},
		watch: {
			addressData() {
				this.freight()
			}
		},
		created() {
			this.$store.commit('user/setClassifyShow', false)
		},
		mounted() {
			this.setItems = this.$store.state.cart.setItems
			this.freight()
			let lang = this.$store.state.i18n.lang
			if (lang == 'zh') {
				this.params.language = 0
			} else if (lang == 'en') {
				this.params.language = 1
			} else if (lang == 'vie') {
				this.params.language = 2
			}
			if (this.jsData.length > 0) {
				this.seleTimeData.push({
					isLj: false,
					label: this.i18n('176'),
					genre: 0,
					delivery_time: 0,
					leftIndex: 0,
					rightIndex: -2,
					spData: this.jsData
				})
			}
			if (this.ydData.length > 0) {
				this.seleTimeData.push({
					isLj: false,
					label: this.i18n('176'),
					genre: 1,
					leftIndex: 0,
					rightIndex: -2,
					delivery_time: 0,
					spData: this.ydData
				})
			}
		}
	};
</script>

<style scoped>
	.spXx {
		width: 875px;
		height: 100%;
		overflow-y: scroll;
		margin-right: 24px;
	}

	.inner {
		flex: 1;
		display: flex;
		overflow-y: scroll;
	}

	.order {
		flex: 1;
		display: flex;
		flex-direction: column;
		padding-left: 50px;
		background: #F5F5F5;
		height: 100%;
	}
</style>
